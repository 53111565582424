<template>
  <v-expansion-panels multiple>
    <v-expansion-panel
      v-for="f in fleets"
      :key="f.id"
    >
      <v-expansion-panel-header>
        {{f.name}}
      </v-expansion-panel-header>
      <v-expansion-panel-content>

        <v-icon>
          mdi-pencil
        </v-icon>
        <aircraft-table
          :aircraft="f.aircraft"
          no-search
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { FleetDTO } from '@/api/flightbooking'
import AircraftTable from '@/components/AircraftTable'
import { mapState, mapMutations } from 'vuex'
export default {
  components: {
    AircraftTable
  },
  created() {
    this.$store.dispatch('aircraft/getFleets')
  },
  computed: {
    ...mapState('aircraft', ['fleets']),
    locale() {
      return 'da-DK' //this.currentUser.locale
    }
  },
  methods: {
    handleClick: function(row) {
      this.$router.push({
        name: 'AircraftDetails',
        params: { aircraftId: row.registration }
      })
    }
  }
}
</script>

<style>
</style>