<template>
  <v-container>
    <fleet-table />
    <aircraft-table :aircraft="aircraft" />
    <fleet-dialog />
  </v-container>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import AircraftTable from '@/components/AircraftTable.vue'
import FleetDialog from '@/components/Aircraft/FleetDialog.vue'
import FleetTable from '@/components/Aircraft/FleetTable.vue'

export default {
  name: 'Aircraft',
  components: {
    AircraftTable,
    FleetDialog,
    FleetTable
  },
  computed: {
    ...mapState('aircraft', ['aircraft'])
  },
  created() {
    this.$store.dispatch('aircraft/getAircraft')
  }
}
</script>

<style></style>
