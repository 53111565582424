<template>
  <v-data-table
    :headers="headers"
    :items="stations"
    :items-per-page="10"
    @click:row="handleClick"
    show-group-by
    dense
    class="elevation-2 row-pointer"
  >
  </v-data-table>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  data: () => ({
    headers: [
      {
        text: 'ICAO',
        value: 'icao',
        groupable: false
      },
      { text: 'IATA', value: 'iata', groupable: false },
      { text: 'Name', value: 'name', groupable: false }
    ],
    dates: []
  }),
  computed: {
    ...mapState('stations', ['stations'])
  },
  created() {
    this.$store.dispatch('stations/getStations')
  },
  methods: {
    handleClick: function(row) {
      this.$router.push({
        name: 'Station',
        params: { icaoCode: row.icao }
      })
    }
  }
}
</script>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>