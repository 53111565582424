<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          bottom
          color="pink"
          dark
          fab
          fixed
          right
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">{{ title }} <v-chip
              class="ma-2"
              small
              color="red"
              text-color="white"
            >INOP</v-chip></span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">

                <v-text-field
                  v-model="fleet.name"
                  :error-messages="modelState.errors && modelState.errors['Name']"
                  label="Fleet name*"
                />
              </v-col>

              <v-col cols="6">
                <h3>Selected</h3>
                <draggable
                  class="list-group"
                  :list="selected"
                  group="aircraft"
                  @change="log"
                >
                  <v-card
                    elevation="4"
                    class="list-group-item"
                    v-for="(element) in selected"
                    :key="element.registration"
                  >
                    <v-card-text>
                      {{ element.registration }}
                    </v-card-text>
                  </v-card>
                </draggable>
              </v-col>
              <v-col cols="6">
                <h3>Available</h3>
                <draggable
                  class="list-group"
                  :list="available"
                  group="aircraft"
                  @change="log"
                >
                  <v-card
                    elevation="4"
                    class="list-group-item"
                    v-for="(element) in available"
                    :key="element.registration"
                  >
                    <v-card-text>
                      {{ element.registration }}
                    </v-card-text>
                  </v-card>
                </draggable>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >Close</v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="UpsertFleet"
          >Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import draggable from 'vuedraggable'
import { mapState, mapMutations } from 'vuex'
export default {
  components: {
    draggable
  },
  data: () => ({
    loading: false,
    modelState: {},
    selected: [],
    dialog: false,

    fleet: {}
  }),
  computed: {
    //...mapState('users', ['users', 'currentUser']),
    ...mapState('aircraft', ['aircraft']),
    title() {
      return !this.fleet.id ? 'New fleet' : 'Edit fleet'
    },
    available() {
      return this.aircraft.filter(function(item) {
        return true
      })
    }
  },
  created() {
    this.$store.dispatch('aircraft/getAircraft')
    this.$store.dispatch('users/getUsers')
  },
  methods: {
    showDialog(fleet) {
      this.loading = true
      this.loading = false
      this.dialog = true
    },

    UpsertFleet() {
      this.$store
        .dispatch('fleets/saveFleet', this.fleet)
        .then(ok => {
          this.dialog = false
          this.modelState = {}
        })
        .catch(error => {
          if (error.status == 400) {
            this.modelState = JSON.parse(error.response)
          } else {
            console.log(error)
          }
        })
    },
    log: function(evt) {
      window.console.log(evt)
    }
  }
}
</script>
<style scoped>
.list-group {
  height: 100%;
  min-height: 150px;
  border: dashed;
  background-color: rgba(173, 216, 230, 0.507);
}
.list-group-item {
  margin: 10px;
  cursor: grab;
  /* background-color: lightgreen; */
}
</style>
