<template>
  <v-container>
    <station-table />
  </v-container>
</template>

<script>
import StationTable from '@/components/Station/StationTable.vue'
export default {
  components: {
    StationTable,
  },
}
</script>

<style></style>
